class WebSocketManager {

  RELATORIOS_ICON = '/img/icon_pdf.png';
  RELATORIOS_TITLE = 'Relatórios';

  CHANNELS = {
    'relatorios': this.processaNotificacaoRelatorio
  };

  constructor(url) {
    this.url = url;
    this.websocket = null;
    this.onmessage = this.onmessage.bind(this);
  }

  connect(afterOpen = null) {
    console.log(`Tentando conectar ao servidor WebSocket: ${this.url}`);

    this.websocket = new WebSocket(this.url);

    this.websocket.onopen = () => this.onopen(afterOpen);
    this.websocket.onmessage = this.onmessage;
    this.websocket.onerror = this.handleError;
  }

  isConnectionActive() {
    return this.websocket != null && this.websocket.readyState === WebSocket.OPEN;
  }

  onopen(afterOpen) {
    if (afterOpen) {
      afterOpen();
    }
  }

  conectaCanal(channelName, filtros = []) {
    this.sendMessage({
      type: 'ENTER',
      channelName: channelName,
      filters: filtros
    });
  }

  onmessage(event) {
    try {
      const body = JSON.parse(event.data);
      if (body.payload) {
        const payload = JSON.parse(body.payload);
        const processChannel = this.CHANNELS[body.channelName];
        if (processChannel) {
          processChannel.call(this, payload);
        }
      }
    } catch (error) {
      console.error("Erro ao processar mensagem do WebSocket:", error);
    }
  }

  handleError(error) {
    console.error("Erro no WebSocket:", error);
  }

  sendMessage(message) {
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(JSON.stringify(message));
    } else {
      console.error("Não foi possível enviar a mensagem. Conexão WebSocket não está aberta.");
    }
  }

  processaNotificacaoRelatorio(payload) {
    const { type, executionId, content, link, progress } = payload;
    switch(type) {
      case 'start':
        NotificationProvider.showToast(
          executionId,
          this.RELATORIOS_TITLE,
          content,
          null,
          null,
          this.RELATORIOS_ICON
        );
        break;
      case 'progress':
        NotificationProvider.showToast(
          executionId,
          this.RELATORIOS_TITLE,
          content,
          parseInt(progress),
          null,
          this.RELATORIOS_ICON
        );
        break;
      case 'end':
        NotificationProvider.showToast(
          executionId,
          this.RELATORIOS_TITLE,
          content,
          null,
          link,
          this.RELATORIOS_ICON
        );
        break;
      default:
        console.warn(`Tipo de notificação desconhecido: ${type}`);
    }
  }
}
