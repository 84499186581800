abstract class NotificationProvider {
    private static toastContainer: HTMLButtonElement;

    private static MAX_TOASTS = 3;

    private static FILA: Array<Toast> = [];

    constructor() {
    }

    static showToast(
        toastId: string,
        title: string,
        mensagem: string,
        progress: number,
        link: string,
        iconPath: string
    ): void {
        this.toastContainer = <HTMLButtonElement>document.getElementById('toastContainer');
        let objectToast = new Toast(toastId, title, mensagem, progress, link, iconPath);
        if ($(`#${toastId}`).length > 0) {
            let toast = $(`#${toastId}`).first();
            this.updateToast(toast, objectToast);
        } else {
            this.FILA.push(objectToast);
            this.processaFila();
        }
    }

    static updateToast(
        toast: JQuery,
        objectToast: Toast
    ) {
        if (objectToast.getMensagem) {
            toast.find('.toast-body p').first().html(objectToast.getMensagem);
        }
        if (objectToast.getProgress != null) {
            if (toast.find('.progress').length > 0) {
                toast.find('.progress').first().css('width', `${objectToast.getProgress}%`);
                toast.find('.progress-text').first().html(`${objectToast.getProgress}%`);
            } else {
                toast.find('.toast-body').first().append(this.criaProgressBar(objectToast));
            }
            toast.find('.toast-body p').first().css('margin', '0 0 10px;')
        } else {
            toast.find('.progress-bar').first().remove();
            toast.find('.toast-itens p').first().css('margin', null)
        }
        if (objectToast.getLink) {
            this.abrirLink(objectToast);
        }
    }

    private static processaFila(): void {
        const toastsVisiveis = this.toastContainer.querySelectorAll('.toast.show');
        if (toastsVisiveis.length < this.MAX_TOASTS && this.FILA.length > 0) {
            const objectToast = this.FILA.shift();

            const toast = document.createElement('div');
            toast.id = objectToast.getToastId;
            toast.className = 'toast show';

            toast.appendChild(this.criaHeader(objectToast));
            toast.appendChild(this.criaBody(objectToast));

            this.toastContainer.appendChild(toast);
        }
    }

    private static criaHeader(objectToast: Toast) {
        let header = document.createElement('div');
        header.className = 'toast-header';

        if (objectToast.getIconPath) {
            let img = document.createElement('img');
            img.src = objectToast.getIconPath
            img.alt = 'Ícone'
            img.className = 'icon';
            header.appendChild(img);
        }

        let titleSpan = document.createElement('span');
        titleSpan.className = 'title';
        titleSpan.innerHTML = objectToast.getTitle;

        let close = document.createElement('div');
        close.onclick = () => {
            $(`#${objectToast.getToastId}`).remove();
        }
        close.innerHTML = '&times;'
        close.className = 'close';

        header.appendChild(titleSpan);
        header.appendChild(close);
        return header;
    }

    private static criaBody(objectToast: Toast) {
        let body = document.createElement('div');
        body.className = 'toast-body';

        let itens = document.createElement('div');
        itens.className = 'toast-itens';

        let p = document.createElement('p');
        p.innerHTML = objectToast.getMensagem;

        if (objectToast.getLink != null) {
            this.abrirLink(objectToast);
        }

        itens.appendChild(p);
        body.appendChild(itens);

        if (objectToast.getProgress != null) {
            let progressBar = this.criaProgressBar(objectToast);
            body.appendChild(progressBar);
        }

        return body;
    }

    private static criaProgressBar(objectToast: Toast) {
        let progressBar = document.createElement('div');
        progressBar.className = 'progress-bar';

        let progressDiv = document.createElement('div');
        progressDiv.className = 'progress';

        let progressText = document.createElement('span');
        progressText.className = 'progress-text';
        if (objectToast.getProgress > 0) {
            progressText.innerText = `${objectToast.getProgress}%`;
        }

        progressDiv.style.width = `${objectToast.getProgress}%`;
        progressDiv.appendChild(progressText);
        progressBar.appendChild(progressDiv);
        return progressBar;
    }

    private static criaBotao(objectToast: Toast) {
        let button = document.createElement('button');
        button.className = 'toast-button';
        button.innerHTML = 'Baixar';
        button.onclick = () => {
            window.open(objectToast.getLink, "_blank");
        }
        return button;
    }

    private static abrirLink(objectToast: Toast) {
        window.open(objectToast.getLink, "_blank");
    }
}

class Toast {
    private toastId: string;
    private mensagem: string;
    private title: string;
    private progress: number;
    private link: string;
    private iconPath: string;

    constructor(toastId: string, title: string, mensagem: string, progress: number, link: string, iconPath: string) {
        this.toastId = toastId;
        this.title = title;
        this.mensagem = mensagem;
        this.progress = progress;
        this.link = link;
        this.iconPath = iconPath;
    }


    public get getMensagem(): string {
        return this.mensagem;
    }

    public get getToastId(): string {
        return this.toastId;
    }

    public get getProgress(): number {
        return this.progress;
    }

    public get getLink(): string {
        return this.link;
    }


    public get getTitle(): string {
        return this.title;
    }

    public get getIconPath(): string {
        return this.iconPath;
    }

}
